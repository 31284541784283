import {api} from '../api'

export const employeesApi = api.injectEndpoints({
  endpoints: (build) => ({
    fetchEmployees: build.query({
      query(payload) {
        return {
          url: `/employee/`,
          method: 'GET',
          params: {
            page: payload?.page,
            ...(payload?.endpoint_id?.length > 0 ? {
              endpoint_id: payload?.endpoint_id?.join(',')
            } : []),
            status: payload?.status,
            ordering: payload?.ordering,
            ...(payload?.text?.length > 0 ? {search_query: payload?.text} : [])
          }
        }
      },
    }),
    fetchEmployeesSearch: build.query({
      query(payload) {
        return {
          url: `/employee/search/`,
          method: 'GET',
          params: {
            page: payload?.page,
            ...(payload?.endpoint_id?.length > 0 ? {
              endpoint_id: payload?.endpoint_id?.join(',')
            } : []),
            status: payload?.status,
            ordering: payload?.ordering,
            ...(payload?.text?.length > 0 ? {search_query: payload?.text} : [])
          }
        }
      },
    }),
    fetchEmployeeId: build.query({
      query(payload) {
        return {
          url: `/employee/${payload.id}/`,
          method: 'GET',
        }
      },
    }),
    fetchEmployeeLimitId: build.query({
      query(payload) {
        return {
          url: `/user-limit/${payload.id}/`,
          method: 'GET',
        }
      },
    }),
    createEmployee: build.query({
      query(payload) {
        return {
          url: `/employee/`,
          method: 'POST',
          data: payload
        }
      },
    }),
    endpointSetPermissions: build.query({
      query(payload) {
        return {
          url: `/endpoint/${payload?.id}/set_permissions/`,
          method: 'POST',
          data: payload
        }
      },
    }),
    createEmployeeUserLimit: build.query({
      query(payload) {
        return {
          url: `/user-limit/`,
          method: 'POST',
          data: payload
        }
      },
    }),
    updateEmployeeId: build.query({
      query(payload) {
        return {
          url: `/employee/${payload?.id}/`,
          method: 'PUT',
          data: payload
        }
      },
    }),
    updateEmployeeIdPassword: build.query({
      query(payload) {
        return {
          url: `/employee/${payload?.id}/change_password/`,
          method: 'POST',
          data: payload
        }
      },
    }),

    employeeResetPassword: build.query({
      query(payload) {
        return {
          url: `/employee/reset_password/`,
          method: 'POST',
          data: payload
        }
      },
    }),
  }),
})
